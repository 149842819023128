import React from 'react';

import Form1 from './Form/Form'
import NavBar1 from './NavBar/NavBar';
import Jumbo1 from './Jumbo1/Jumbo1'
import Product from './Product/Product';
import Contacts from './Contacts/Contacts';
import Testimonial from './Testimonial/Testimonial';



const Homepage = () => {
    return (  
        <div>
            <NavBar1 />
            <Jumbo1 />
            <Product />
            <Form1 />
            <Testimonial />
            <Contacts />
        </div>
    );
}
 
export default Homepage;