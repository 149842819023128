
import React from 'react';
import { Container } from 'react-bootstrap';
import NavBar1 from '../../Homepage/NavBar/NavBar';
import Contacts from '../../Homepage/Contacts/Contacts';
import img0123 from '../../../media/0123.png';

import './styles.css';

const BrokerPage = () => {
  return (
    <div>
      {/* Thêm NavBar1 */}
      <NavBar1 />

      {/* Nội dung trang */}
      <Container>
        <h2 className="fw-bold mt-4">Sản phẩm môi giới chứng khoán "True Invest"</h2>
        <p>
          <strong>"True Invest"</strong> là dịch vụ môi giới chứng khoán chuyên nghiệp, cung cấp cho khách hàng các chiến lược đầu tư hiệu quả với mức độ rủi ro được kiểm soát chặt chẽ. 
          Trong năm 2025, chúng tôi dự kiến đưa ra từ <span className="highlight">100 đến 150 lệnh khuyến nghị</span>.
        </p>
        <p>
          Mục tiêu là giúp khách hàng đạt <span className="highlight">lợi nhuận trung bình kỳ vọng +15%</span> mỗi lệnh thành công (có thể tăng lên +18% đến +20% trong điều kiện thuận lợi) với mức dừng lỗ trung bình chỉ <span className="highlight">-5%</span>.
        </p>

        <p>Anh chị có thể xem thêm phần giới thiệu phương pháp đầu tư 0-1-2-3 của chúng tôi ở bên dưới</p>
        <h3 className="fw-bold mt-4">Dịch vụ của chúng tôi nhấn mạnh vào:</h3>
        <ul>
          <li><strong>Tỷ lệ thành công cao:</strong> Cam kết đạt tỷ lệ thắng 50% trong tổng số lệnh khuyến nghị.</li>
          <li><strong>Quản lý vốn thông minh:</strong> Khuyến nghị sử dụng 20% vốn trong mỗi lệnh để đảm bảo an toàn và linh hoạt.</li>
          <li><strong>Chiến lược đầu tư minh bạch:</strong> Khách hàng sẽ được tham khảo các kịch bản giải ngân cụ thể nhằm tối ưu hóa hiệu quả đầu tư.</li>
        </ul>
        <p>
          Hãy đồng hành cùng chúng tôi để hiện thực hóa mục tiêu tài chính trong năm 2025 với sự hỗ trợ của đội ngũ chuyên gia giàu kinh nghiệm!
        </p>
        <div className="cta">
            <h3 className="fw-bold">Liên hệ với chúng tôi qua Zalo: 0968.42.01.09 để được tư vấn</h3>
        </div>

        <hr />
        {/* Hệ thống 0 1 2 3 giải thích đơn giản */}
        <div>
            <h3 className="fw-bold mt-4">Hệ thống giao dịch 0-1-2-3</h3>
            <p>
            Hệ thống giao dịch 0-1-2-3 là một phương pháp giao dịch dựa trên chu kỳ vận động tự nhiên của cổ phiếu, được chia thành 4 giai đoạn: thoái trào, tạo đáy, khởi động, và bứt tốc. Hệ thống giúp nhà đầu tư cá nhân, đặc biệt là những người không chuyên, chọn điểm mua cổ phiếu hiệu quả dựa trên xu hướng trung và dài hạn, giảm thiểu ảnh hưởng của cảm xúc và thông tin nhiễu.
            </p>
            <h4>Điểm mua 0, 1, 2, 3 trong hệ thống 0-1-2-3:</h4>
            <ul>
            <li><strong>Điểm mua 0:</strong> Điểm mua tốt nhất khi cổ phiếu chính thức vào xu hướng tăng. Mang lại upside lớn nhất và rủi ro thấp nhất, phù hợp để mua đủ khối lượng mục tiêu và nắm giữ lâu dài.</li>
            <li><strong>Điểm mua 1:</strong> Khởi đầu xu hướng tăng ngắn hạn. Phù hợp lướt sóng T+, nhưng rủi ro cao hơn điểm 0. Thường nên mua ½ khối lượng và chờ điểm 2 bổ sung.</li>
            <li><strong>Điểm mua 2:</strong> Xuất hiện trong giai đoạn tăng mạnh hơn. Dừng lỗ tối đa 5%, phù hợp để mua đủ khối lượng mục tiêu nếu chưa tham gia ở điểm 1.</li>
            <li><strong>Điểm mua 3:</strong> Giai đoạn tăng mạnh thứ hai, rủi ro cao nhất với dừng lỗ tối thiểu 10%. Chỉ nên tham gia với ½ khối lượng và không nên đi vốn lớn.</li>
            </ul>

            <div className="text-center my-4">
                <img src={img0123} alt="Hệ thống giao dịch 0-1-2-3" className="img-fluid" />
            </div>
            <h4>Tại sao nên dùng hệ thống 0-1-2-3?</h4>
            <p>
            Hệ thống giúp nhà đầu tư cá nhân dễ dàng áp dụng nhờ tính đơn giản, tập trung vào xu hướng giá và giảm bớt sự phụ thuộc vào các dự đoán. Kết quả backtest (2020-2024) cho thấy hệ thống hiệu quả trong việc tối ưu lợi nhuận.
            </p>
            <h4>Ưu điểm:</h4>
            <p>
            Hệ thống dễ vận hành, yêu cầu ít tài nguyên, và tích hợp sẵn quản trị rủi ro. Tỷ lệ lợi nhuận/rủi ro tối ưu (PNL 3:1) giúp nhà đầu tư thắng tổng thể ngay cả khi thị trường biến động bất ngờ.
            </p>
        </div>
      </Container>

      <Contacts/>
    </div>
  );
};

export default BrokerPage;

