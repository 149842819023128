import React from 'react';
import {Link, NavLink} from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

import './styles.css'

import navbar_logo from '../../../media/navbar_logo.png'

const NavBar1 = () => {
    return (  
        <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img 
                        src={navbar_logo }
                        alt='navbar-logo'
                        height='30'
                    />

                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link>
                        <Link className="text-decoration-none nav-el" to='/'>Trang chủ</Link>
                    </Nav.Link>
                    <Nav.Link>
                        <Link className="text-decoration-none nav-el" to='/about'>Về chúng tôi</Link>
                    </Nav.Link>



                    <NavDropdown title="Sản phẩm" id="basic-nav-dropdown">
                        <NavDropdown.Item as={Link} to="/product/broker">Môi giới CK</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                            Ứng dụng hỗ trợ đầu tư
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Quản lý tài khoản</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item>
                            <Link className="text-decoration-none nav-el" to='/product/course'>Khoá học</Link>
                        </NavDropdown.Item>
                    </NavDropdown>
                    
                    <Nav.Link>
                        <Link className="text-decoration-none nav-el" to='/market'>Thị trường hôm nay</Link>
                    </Nav.Link>
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>        
    );
}
 
export default NavBar1;