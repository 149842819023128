import React from 'react';
import Container from 'react-bootstrap/esm/Container';

import './Jumbo1.css'

import mobilePreview from '../../../media/mobile.jpg';
import person from '../../../media/person.png';
import trade from '../../../media/trade.png';
import filter1 from '../../../media/filter.png';
import bell from '../../../media/bell.png';
import QRcode from '../../../media/QR.png';


const Jumbo1 = () => {
    return (  
        <Container className="jumbo1" >
            <div className='headline col-12 col-md-6'>
                <h2>TRẢI NGHIỆM NGAY <br/>APP TƯ VẤN ĐẦU TƯ <br/><span style={{color: '#4CBB17'}}>SWING TRADE</span></h2>
                <div className='benefit'>
                    {/* 4 columns here */}
                    <div className='item col-md-3 d-flex flex-wrap justify-content-center'>
                        <img 
                            src={person}
                            alt="person"
                            style={{width: '40px'}}
                        />
                        <p className='text-center'>Cá nhân hoá danh mục</p>
                    </div>
                    {/* 2nd */}
                    <div className='item col-md-3 d-flex flex-wrap justify-content-center'>
                        <img 
                            src={trade}
                            alt="trade"
                            style={{width: '40px'}}
                        />  
                        <p className='text-center'>Chiến lược giao dịch cụ thể</p>
                    </div>
                    {/* 3rd */}
                    <div className='item col-md-3 d-flex flex-wrap justify-content-center'>
                        <img 
                            src={filter1}
                            alt="filter"
                            style={{width: '40px', height: '40px'}}
                        />
                        <p className='text-center'>Thông tin chắt lọc</p>
                    </div>
                    {/* 4th */}
                    <div className='item col-md-3 d-flex flex-wrap justify-content-center'>
                        <img 
                            src={bell}
                            alt="bell"
                            style={{width: '40px', height: '40px'}}
                        />
                        <p className='text-center'>Thông báo kịp thời</p>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6'>

                {/* mobile screen */}
                <img 

                    src={mobilePreview}
                    alt="QR" border="0" 
                    style={{width: '200px', margin: '50px'}}
                />
                {/* QR code */}
                <img className='jumbo1_qrcode'
                    src={QRcode}
                    alt="QR" border="0" 
                    style={{width: '100px'}}
                />


            </div>
        </Container>
    );
}
 
export default Jumbo1;