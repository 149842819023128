import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './styles.css'

import KhanhMinh from '../../../media/testi/KhanhMinh.jpg';
import LongDoManh from '../../../media/testi/LongDoManh.jpg';
import ChungDo from '../../../media/testi/ChungDo.jpg';

const Testimonial = () => {
    return (  

        <div>
            <h2>CẢM NHẬN HỌC VIÊN</h2>
            <div id='testimonial-desktop'>
                <blockquote class="otro-blockquote col-md-3">
                        <div class="otro-blockquote-img">
                            <img src={KhanhMinh}></img>
                        </div>
                        <span>Khánh Minh</span>
                        Đức là người rất giỏi, chuyên sâu nhiều năm nghiên cứu. Từng là trưởng phòng nghiên cứu am hiểu sâu về cả Phân tích cơ bản và Phân tích kỹ thuật. Những khuyến nghị của Đức đưa ra đều có cơ sở rõ ràng và đạt hiệu quả. Rất cảm ơn đã biết Đức và True Invest!
                </blockquote>

                <blockquote class="otro-blockquote col-md-3">
                        <div class="otro-blockquote-img">
                            <img src={LongDoManh}></img>
                        </div>
                        <span>Đỗ Mạnh Long</span>
                        A Đức giảng viên là người rất nhiệt tình, và có kiến thức rất sâu và rộng không chỉ trong chứng khoán mà còn ở các lĩnh vực đầu tư khác. Nếu thực sự quan tâm đến chứng khoán và không có quá nhiều thời gian theo dõi bảng điện thì đây là nơi rất phù hợp để bắt đầu.
                </blockquote>

                <blockquote class="otro-blockquote col-md-3">
                        <div class="otro-blockquote-img">
                            <img src={ChungDo}></img>
                        </div>
                        <span>Chung Đỗ</span>
                        Mình đã hoàn thành 2 khoá học của True Invest, là khoá cơ bản và khoá nâng cao. Sau một thời gian, mình cảm nhận được rõ rệt là những phương pháp các bạn định hướng đã giúp mình bù đắp lại được những thiếu sót của 4 năm về trước để có được kết quả khá ổn như hiện tại. High recommend 1 địa chỉ uy tín cho tất cả các bạn muốn đi sâu và thành công trong lĩnh vực này.
                </blockquote>
            </div>

            <div id='testimonial-mobile'>
                <Slider
                    slidesToShow={1}
                    slidesToScroll={1}
                    arrows={true} // set to true to show navigation arrows
                    responsive={[ // set responsive breakpoints for different screen sizes
                      {
                        breakpoint: 768, // mobile breakpoint
                        settings: {
                          slidesToShow: 1, // show 1 slide at a time on mobile
                          slidesToScroll: 1
                        }
                      }]}
                >

                    <blockquote class="otro-blockquote col-md-3">
                            <div class="otro-blockquote-img">
                                <img src={KhanhMinh}></img>
                            </div>
                            <span>Khánh Minh</span>
                            Đức là người rất giỏi, chuyên sâu nhiều năm nghiên cứu. Từng là trưởng phòng nghiên cứu am hiểu sâu về cả Phân tích cơ bản và Phân tích kỹ thuật. Những khuyến nghị của Đức đưa ra đều có cơ sở rõ ràng và đạt hiệu quả. Rất cảm ơn đã biết Đức và True Invest!
                    </blockquote>

                    <blockquote class="otro-blockquote  col-md-3">
                            <div class="otro-blockquote-img">
                                <img src={LongDoManh}></img>
                            </div>
                            <span>Đỗ Mạnh Long</span>
                            A Đức giảng viên là người rất nhiệt tình, và có kiến thức rất sâu và rộng không chỉ trong chứng khoán mà còn ở các lĩnh vực đầu tư khác. Nếu thực sự quan tâm đến chứng khoán và không có quá nhiều thời gian theo dõi bảng điện thì đây là nơi rất phù hợp để bắt đầu.
                    </blockquote>

                    <blockquote class="otro-blockquote col-md-3">
                            <div class="otro-blockquote-img">
                                <img src={ChungDo}></img>
                            </div>
                            <span>Chung Đỗ</span>
                            Mình đã hoàn thành 2 khoá học của True Invest, là khoá cơ bản và khoá nâng cao. Sau một thời gian, mình cảm nhận được rõ rệt là những phương pháp các bạn định hướng đã giúp mình bù đắp lại được những thiếu sót của 4 năm về trước để có được kết quả khá ổn như hiện tại. High recommend 1 địa chỉ uy tín cho tất cả các bạn muốn đi sâu và thành công trong lĩnh vực này.
                    </blockquote>
                </Slider>

            </div>
        </div>
    );
}
 
export default Testimonial;

