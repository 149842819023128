import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col } from 'react-bootstrap';
import NavBar1 from '../Homepage/NavBar/NavBar';

import extractDateComponents from '../../tool/extractDateTime';
import DataTableTop30 from '../../item/DataTableTop30';
import TopSectorTable from '../../item/TopSectorTable';
import StrategyTable from '../../item/StrategyTable';
import './TodayMarket.css'
const TodayMarket = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [date, setDate] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://trueinvestvn.site/api/daily/lastest');
                setData(response.data);

                const { year, month, day } = extractDateComponents(response.data.entryDate);
                const formattedDate = `${day}/${month}/${year}`;
                setDate(formattedDate);

                console.log(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Request error:', err);
                setError('Failed to fetch data');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="error">{error}</div>;
    }

    return (
        <div>
            <NavBar1 />
            <Container className="home_page">
                <Row>
                    {/* On mobile, xs, strategy_table takes full width, and will be placed first */}
                    {/* Bang chien luoc - Daily Strategy */}
                    <Col xs={12} md={4} className="order-md-2 strategy_table">
                        <div>
                            <h2>Chiến lược thị trường ngày {date}</h2>
                            {data ? (
                                <StrategyTable data={[
                                    data.marketToday,
                                    data.marketTrend,
                                    data.timeTrend,
                                    data.strategy,
                                    data.capitalAllocation
                                ]} />
                            ) : (
                                <p>No data available</p>
                            )}
                        </div>
                    </Col>

                    {/* On mobile, xs, signal_table takes full width */}
                    {/* Bang Top 30 RS - Top Sector RS */}
                    <Col xs={12} md={8} className="order-md-1 signal_table">
                        <div>
                            <h2>30 Mã CP đáng chú ý</h2>
                            {data && data.topRsStock ? (
                                <DataTableTop30 data={data.topRsStock} />
                            ) : (
                                <p>No data available</p>
                            )}

                            <p className="note">Đây là danh sách 30 cổ phiếu đáng chú ý, có thể sẽ tăng mạnh, có thể sắp có điểm mua trong thời gian tới, không phải là khuyến nghị mua ngay lập tức trong phiên sau</p>
                            <h2>Các ngành/ Index đáng chú ý</h2>
                            {data && data.topRsSector ? (
                                <TopSectorTable data={data.topRsSector} />
                            ) : (
                                <p>No data available</p>
                            )}

                            <p className="note">Nhà đầu tư có thể tìm kiếm cơ hội từ các cổ phiếu có trong các index ở trên</p>
                        </div>

                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TodayMarket;
