import React from 'react';
import { Container } from 'react-bootstrap';
import Contacts from '../Homepage/Contacts/Contacts';
import NavBar1 from '../Homepage/NavBar/NavBar';

import './styles.css'

const About = () => {
    return (  
        <div>
            <NavBar1 />

            <Container className='mt-3'>
                <div className='col-12 col-md-6 '>
                    <h3>Tầm nhìn</h3>
                    <p>TRUE INVEST mong muốn trở thành tổ chức đầu tư uy tín hàng đầu, là cộng sự đồng hành với các tổ chức, cá nhân mong muốn phát triển tài sản một cách an toàn và bền vững.</p>
                </div>

                {/* Sứ mệnh */}
                <div className='col-12 col-md-6 offset-md-6'>
                    <h3>Sứ mệnh</h3>
                    <ul className='p-0'>
                        <li className='mb-3'>
                            <span className='fw-bold'>Đối với khách hàng</span> <br/>
                            Cung cấp các dịch vụ tài chính chất lượng hàng đầu dựa trên nền tảng công nghệ hàng đầu kết hợp với đội ngũ nhân sự có năng lực chuyên môn cao
                        </li>
                        <li  className='mb-3'>
                            <span className='fw-bold'>Đối với cổ đông</span> <br/>
                            Trung thực, minh bạch và mang lại lợi ích bền vững
                        </li>

                        <li  className='mb-3'>
                            <span className='fw-bold'>Đối với cán bộ nhân viên</span> <br/>
                            Xây dựng môi trường làm việc chuyên nghiệp, năng động giúp cán bộ nhân viên có thể phát triển không ngừng
                        </li>
                    </ul>
                </div>

                {/* Giá trị cốt lõi */}
                <div className='col-12 col-md-6'>
                    <h3>Giá trị cốt lõi</h3>
                    <ul className='p-0'>
                        <li>Làm việc tận tâm và hết mình</li>
                        <li>Khiêm tốn, nỗ lực phát triển và đổi mới không ngừng</li>
                        <li>Chia sẻ giá trị đến khách hàng, cổ đông và mọi thành viên trong công ty</li>
                    </ul>
                </div>
            </Container>

            <Contacts />
        </div>
    );
}
 
export default About;